<template>
  <div class="about">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-2">Join the PFDAA</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <p class="org-description is-size-6">
        Membership in the PFDAA offers a variety of benefits, including week long summer dance
        festivals, access to our music and video library, and opportunities to work with
        choreographers from Poland during workshops.
        <br/>Past festivals have offered our members a variety of festival sites including Detroit,
        Philadelphia, Chicago, Baltimore, San Francisco, Boston, Minneapolis, New York, and
        Montreal. These festivals provide an excellent environment for meeting new people,
        learning new repertoires from well known instructors, and the opportunity to renew and
        refine your ensemble’s repertoire.
        <br/>Other membership benefits include discount registration fees for festivals and workshops,
        and an expansive audio and video library from many regions of Poland to be used for
        research or for your own viewing and listening pleasure. In 1999, 2005, and 2011, the PFDAA
        organized a performing tour of Poland prior to the Rzeszów Festival.
        <br/>As an association, we are always striving to bring forth new ideas to help gain knowledge of
        Polish folk dance, provide participating ensembles an avenue for networking, and increase
        our member groups' exposure. The PFDAA newsletter and website keep member groups
        abreast of association activities and is an excellent means of sharing your group’s activities
        with the association's members.
        <br/>Membership in the association can be a rewarding social experience and educational tool
        for your organization. Your initial membership fee of $125.00 USD helps support processing
        your application, technology fees, and music acquisition. Annual dues are $35.00 USD
        thereafter.
        If you have any more questions, please feel free to email us at
        <a
            href="mailto: pfdaacontact@gmail.com"
        >pfdaacontact@gmail.com</a>
      </p>
      <a
          href="https://firebasestorage.googleapis.com/v0/b/pfdaa-7f092.appspot.com/o/PFDAA%20Welcome%20Letter%20%26%20Application.pdf?alt=media&token=4a6ee913-f672-4515-bf34-3baed5b97142"
          download="welcomeLetterAndApplication.pdf"
      >
        <button class="welcome button">Welcome Letter and Application</button>
      </a>

      <GMapMap
          :center="{lat: 40.306106, lng:-97.942105}"
          :zoom="4"
          map-type-id="terrain"
          style="width: 800px; height: 600px;"
          id="gmap"
      >
        <GMapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="false"
            :draggable="false"
        />
      </GMapMap>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import axios from "axios";
import EnsembleService from "../services/ensembleService";

export default {
  name: "Join",
  data() {
    return {
      markers: []
    };
  },

  async mounted() {
    let markers = [];
    let apiKey = "AIzaSyAyQ4Q-vYM8JKQz0pO8JN8foESjYhF9gLk";
    let ensembles = await EnsembleService.getAll();
    for (const ensemble of ensembles) {
      if (ensemble.hasOwnProperty("latitude") && ensemble.hasOwnProperty("longitude")) {
        markers.push({
          position: {lat: ensemble.latitude, lng: ensemble.longitude},
          name: ensemble.name
        });
      } else {
        let link = `https://maps.googleapis.com/maps/api/geocode/json?address=${ensemble.zip}&key=${apiKey}`;
        let backupLink = `https://maps.googleapis.com/maps/api/geocode/json?address=${ensemble.city},${ensemble.state}&key=${apiKey}`;
        let latitude, longitude;
        await axios
            .get(link)
            .then(response => {
              let geometry = _.get(
                  response,
                  "data.results[0].geometry.location"
              );
              latitude = geometry.lat;
              longitude = geometry.lng;
              if (latitude && longitude) {
                EnsembleService.update(ensemble.id, {latitude: latitude, longitude: longitude})
              }
              markers.push({
                position: {lat: geometry.lat, lng: geometry.lng},
                name: ensemble.name
              });
            })
            .catch(async () => {
              await axios.get(backupLink).then(response => {
                let geometry = _.get(
                    response,
                    "data.results[0].geometry.location"
                );
                latitude = geometry.lat;
                longitude = geometry.lng;
                if (latitude && longitude) {
                  EnsembleService.update(ensemble.id, {latitude: latitude, longitude: longitude})
                }
                markers.push({
                  position: {lat: geometry.lat, lng: geometry.lng},
                  name: ensemble.name
                });
              });
            });
      }
    }
    this.markers = markers;
  },
}
</script>
<style lang="scss" scoped>
.org-description {
  margin-top: 50px;
}

#gmap {
  margin: 20px;
}

.hero-body {
  background: #ed213a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
          to right,
          #93291e,
          #ed213a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
          to right,
          #93291e,
          #ed213a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.welcome {
  margin-bottom: 10px;
  transition: 0.3s;

}

.welcome:hover {
  background-color: #3e8e41;
  color: white;
}
</style>